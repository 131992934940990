import _ from "lodash";
import { CSSProperties, PureComponent, ReactNode, createRef } from "react";
import SignaturePad from "react-signature-pad-wrapper";
import withCommonEvents from "../../../shared/hoc/with-common-events";

interface SignatureProps {
  value?: string;
  style?: CSSProperties;
  onChange?: (value: string) => void;
  throttle?: number;
  dotSize?: number;
  id?: string;
}

interface SignatureState {}

class Signature extends PureComponent<SignatureProps, SignatureState> {
  private sigCanvas;

  private sigCanvasWrapper;

  constructor(props: SignatureProps) {
    super(props);
    this.sigCanvas = createRef();
    this.sigCanvasWrapper = createRef();
  }

  begin = (value: string) => {
    this.sigCanvas.current.clear();

    if (value) {
      this.sigCanvas.current.fromDataURL(value);
    }
  };

  end = () => {
    if (this.props.onChange) {
      if (this.sigCanvas.current) {
        if (this.sigCanvas.current?.isEmpty() === true) {
          this.props.onChange("");
        } else {
          const dataURL = this.sigCanvas.current?.toDataURL("image/png");
          this.props.onChange(dataURL);
        }
      }
    }
  };

  getStyleProp = (): CSSProperties => {
    const defaultStyle: CSSProperties = {
      width: "100%",
      height: "300px"
    };

    return { ...defaultStyle, ..._.cloneDeep(this.props.style) };
  };

  getColor = () => {
    const style = this.getStyleProp();
    if (style && style.color) {
      return style.color;
    }
    return "black";
  };

  getBgColor = () => {
    const style = this.getStyleProp();
    if (style && style.backgroundColor) {
      return style.backgroundColor;
    }
    return "white";
  };

  componentDidUpdate(prevProps: Readonly<SignatureProps>, prevState: Readonly<SignatureState>, snapshot?: any): void {
    if (this.props.value === undefined || this.props.value === "") {
      this.sigCanvas.current.clear();
    }

    if (this.props.value !== prevProps.value) {
      this.begin(this.props.value);
    }
  }

  render(): ReactNode {
    const style = this.getStyleProp();

    return (
      <div ref={this.sigCanvasWrapper} style={style} className="kuika-canvas-input">
        <SignaturePad
          redrawOnResize
          options={{
            throttle: this.props.throttle,
            dotSize: this.props.dotSize,
            penColor: this.getColor(),
            backgroundColor: this.getBgColor(),
            onBegin: () => this.begin(this.props.value),
            onEnd: () => this.end()
          }}
          ref={this.sigCanvas}
        />
      </div>
    );
  }
}

const signature = withCommonEvents(Signature);
export { signature as Signature };
