import withCommonEvents from "../../../shared/hoc/with-common-events";
import { BaseTable, ITableProps } from "./base-table";
import { FlexGridBodyWrapperProps } from "./body/flex-grid/flex-grid-body-wrapper";
import { FlexGridItemProps } from "./body/flex-grid/flex-grid-item";

class FlexGrid extends BaseTable<FlexGridItemProps & FlexGridBodyWrapperProps, any> {
  constructor(props: ITableProps & FlexGridItemProps & FlexGridBodyWrapperProps) {
    super(props);
  }

  public static defaultProps = {
    mode: "flex-grid",
    showHeader: false,
    designClicked: false
  };
}

const flexGrid = withCommonEvents(FlexGrid);
export { flexGrid as FlexGrid };
